import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const LeerOnsKennen = () => {
  return (
    <div className="bg-black relative font-inter mt-24 lg:mt-40">
      <div className="grid grid-cols-12">
        <div className="col-span-1"></div>
        <div className="col-span-10 lg:col-span-4 flex flex-col items-center justify-center ">
          <div className="text-3xl sm:text-5xl font-semibold text-gray-white py-16 ">
            Wij werken samen om uw dossier de aanpak te geven die het verdient.{" "}
          </div>
        </div>
        <div className="col-span-1"></div>
        <div className="col-span-12 lg:col-span-6 relative">
          <StaticImage
            src="https://res.cloudinary.com/yungnuts/image/upload/v1644150857/CVT/_YDG0813_lahvid.jpg"
            alt="Mooie foto"
            className="h-full"
          />
          <div className="absolute bg-blue-effect text-gray-white  top-0 right-0">
            <Link
              className="px-8  flex flex-col w-full justify-center items-center  "
              to="/team"
            >
              <div className="flex flex-row justify-center w-48 space-x-4  py-4    ">
                <div className=" ">Ontdek ons team</div>
                <div className="">
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M13.75 6.75L19.25 12L13.75 17.25"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M19 12H4.75"
                    ></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeerOnsKennen;
