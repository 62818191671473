import React from "react";
import { Link } from "gatsby";

const Ontdek = () => {
  return (
    <div className="bg-gray-white grid grid-cols-12 font-inter mt-20 lg:mb-32">
      <div className="col-span-1 hidden sm:block sm:col-span-1 lg:col-span-3"></div>
      <div className="text-2xl sm:text-5xl col-span-12 sm:col-span-10 lg:col-span-6  text-black font-semibold mt-20 px-10 sm:px-0">
        Kwaliteit in diverse praktijkgebieden
      </div>
      <div className="col-span-1 hidden sm:block sm:col-span-1 lg:col-span-3"></div>

      <div className="col-span-1 hidden sm:block sm:col-span-1 lg:col-span-3"></div>
      <div className="font-light text-black  mt-12 mb-8 col-span-12 sm:col-span-10 lg:col-span-6 sm:text-xl 2xl:text-xl px-10 sm:px-0">
        {/* CVT advocaten heeft jarenlange expertise in het beschermen van
          klanten. Ons team bestaat uit verschillende experts in alle
          vakgebieden van het gerecht. Voor elke situatie is een andere aanpak
          en strategie nodig. Dankzij onze ervaring kunnen we hierin snel
          schakelen en kostbare tijd besparing om de beste argumentatie te
          zoeken. */}{" "}
  
        U kan bij ons terecht met uiteenlopende juridische vragen. Wij garanderen een volwaardige juridische ondersteuning. Door een uitgebreide bibliotheek blijven wij up-to-date. 
        <div>
          <br />
        </div>
        <div>
        
        
        </div>{" "}
      </div>
      <div className="col-span-1 hidden sm:block sm:col-span-1 lg:col-span-3"></div>
      <div className="col-span-1 hidden sm:block sm:col-span-1 lg:col-span-3"></div>
      <div className="hidden sm:block">
        <Link
          className="  flex flex-col w-full justify-center "
          to="/praktijkgebieden"
        >
          <div className="flex flex-row justify-center w-64 space-x-4  py-4 text-gray-white bg-blue-effect  ">
            <div className=" ">Onze praktijkgebieden</div>
            <div className="">
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M13.75 6.75L19.25 12L13.75 17.25"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19 12H4.75"
                ></path>
              </svg>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Ontdek;
