import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    // sm:text-blue-effect md:text-red-effect lg:text-yellow xl:text-green-effect
    <div className="lg:h-screen  bg-gray-white  xl:mb-0 font-inter">
      <div className="grid grid-cols-12  w-full h-full   ">
        {/* grid A */}
        <div className="grid grid-cols-12 col-span-12 lg:col-span-6 relative">
          <div className="col-span-2  md:col-span-0"></div>
          {/* grid 1: foto */}
          <div className="hidden  col-span-8 lg:col-span-12 mt-20 md:mt-0  lg:h-screen 2xl:max-h-screen lg:block ">
            <StaticImage
              // kristof
              // src="https://res.cloudinary.com/yungnuts/image/upload/v1643545587/CVT/hero.jpg"
              // bureau
              src="https://res.cloudinary.com/yungnuts/image/upload/v1644150619/CVT/_YDG0838_m9aosd.jpg"
              // boom buiten
              // src="https://res.cloudinary.com/yungnuts/image/upload/v1644150676/CVT/WEB4_ptx1rh.jpg"
              // kantoor buiten
              // src="https://res.cloudinary.com/yungnuts/image/upload/v1643545534/CVT/WEB3_dtivle.jpg"
              alt="Mooie foto"
              className="h-full  "
            />
          </div>
          <div className="col-span-2  lg:col-span-0"></div>
        </div>

        {/* grid B */}
        <div className="grid grid-cols-12 col-span-12 lg:col-span-6 pt-4 lg:pt-0 mt-36 md:mt-18 lg:mt-0">
          {/* grid 2: hele tekst blok */}
          <div className="col-span-1  lg:col-span-0"></div>
          <div className="col-span-10 lg:col-span-12    flex flex-col  w-full  h-full text-black-light    justify-center">
            <div className="lg:px-10 xl:px-16 sm:text-4xl md:mt-16 lg:mt-0 lg:text-5xl xl:text-7xl font-semibold">
              CVT advocaten
            </div>
            <div className="text-3xl sm:text-5xl mt-4  md:text-5xl lg:text-4xl xl:text-4xl lg:px-10 xl:px-16 lg:mt-4  2xl:pr-64  font">
              Uw recht is onze zorg. Wij luisteren naar uw verhaal, vertalen dit juridisch en zoeken naar een concrete oplossing.
              #OmdatUerRechtOpHebt
            </div>

            {/* knop */}
            <Link
              className="hidden md:px-10 xl:px-16 mt-8 lg:flex flex-col w-full justify-center "
              to="/contact"
            >
              <div className="flex flex-row justify-center w-48 space-x-4  py-4 text-gray-white bg-blue-effect  ">
                <div className=" ">Contacteer ons</div>
                <div className="">
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M13.75 6.75L19.25 12L13.75 17.25"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M19 12H4.75"
                    ></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-span-1  md:col-span-0"></div>
        </div>

        {/* grid C mobile */}
        <div className="grid grid-cols-12 col-span-12 lg:col-span-6">
          <div className="col-span-0  lg:col-span-0"></div>
          {/* grid 1: foto */}
          <div className=" lg:hidden col-span-12  mt-20  ">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1644150619/CVT/_YDG0838_m9aosd.jpg"
              alt="Mooie foto"
              className="h-full lg:h-screen max-h-screen"
            />
          </div>
          <div className="col-span-0  lg:col-span-0"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
