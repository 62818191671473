import React from "react";
import { StaticImage } from "gatsby-plugin-image";


const Waarden = () => {
  return (
    <div className="lg:h-screen  bg-gray-white font-inter  xl:mb-0">
      <div className="grid grid-cols-12  w-full h-full   ">
        {/* grid A */}
        <div className="grid grid-cols-12 col-span-12 lg:col-span-6 relative">
          <div className="col-span-2  md:col-span-0"></div>
          {/* grid 1: foto */}
          <div className="hidden  col-span-8 lg:col-span-12 md:mt-0 2xl:col-span-9 2xl:p-24 lg:h-screen 2xl:max-h-screen lg:block ">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1644150676/CVT/WEB4_ptx1rh.jpg"
              alt="Mooie foto"
              className="h-full  "
            />
          </div>
          <div className="col-span-2  lg:col-span-0"></div>
        </div>
        {/* grid B */}
        <div className="grid grid-cols-12 col-span-12 lg:col-span-6 pt-4 lg:pt-0 sm:mt-12 md:mt-20 lg:mt-0">
          {/* grid 2: hele tekst blok */}
          <div className="col-span-1  lg:col-span-0"></div>
          <div className="col-span-10 lg:col-span-12    flex flex-col  w-full  h-full text-black-light    justify-center">
            <div className="lg:px-10 xl:px-16 text-2xl sm:text-5xl  lg:mt-0 md:text-5xl xl:text-5xl font-semibold">
              Waarden van CVT
            </div>

            <div className="text-xl sm:text-4xl mt-16  md:text-3xl lg:text-3xl xl:text-3xl lg:px-10 xl:px-16 lg:mt-16  2xl:pr-64">
              Duidelijk
            </div>

            <div className="px-8 lg:px-16 xl:px-20 sm:text-xl font-light">
              Wij zeggen waar het op staat.
            </div>
            <div className="text-xl sm:text-4xl mt-4   md:text-3xl lg:text-3xl xl:text-3xl  lg:px-10 xl:px-16 lg:mt-16  2xl:pr-64 ">
              Toegankelijk
            </div>
            <div className="px-8 lg:px-16 xl:px-20 sm:text-xl font-light">
              Wij spreken een taal die u begrijpt.
            </div>
            <div className="text-xl sm:text-4xl mt-4   md:text-3xl lg:text-3xl xl:text-3xl  lg:px-10 xl:px-16 lg:mt-16  2xl:pr-64 ">
              Praktisch
            </div>
            <div className="px-8 lg:px-16 xl:px-20 sm:text-xl font-light">
              Wij werken naar een concrete oplossing.
            </div>
            <div className="text-xl sm:text-4xl mt-4   md:text-3xl lg:text-3xl xl:text-3xl  lg:px-10 xl:px-16 lg:mt-16  2xl:pr-64 ">
              Transparant
            </div>
            <div className="px-8 lg:px-16 xl:px-20 sm:text-xl font-light">
              U kent op elk ogenblik de stand van uw dossier en de kosten.
            </div>

            {/* knop */}
          </div>
          <div className="col-span-1  md:col-span-0"></div>
        </div>

        {/* grid C mobile */}
        <div className="grid grid-cols-12 col-span-12 lg:col-span-6">
          <div className="col-span-0  lg:col-span-0"></div>
          {/* grid 1: foto */}
          <div className=" lg:hidden col-span-12  mt-20  ">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1644150676/CVT/WEB4_ptx1rh.jpg"
              alt="Mooie foto"
              className="h-full lg:h-screen max-h-screen"
            />
          </div>
          <div className="col-span-0  lg:col-span-0"></div>
        </div>
      </div>
    </div>
  );
};

export default Waarden;
