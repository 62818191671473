import React from "react";
import '../../styles/global.css'

const Statistieken = () => {

  
  return (
    <div className=" bg-gray-white font-inter mt-32 lg:mt-40">
      <div className="grid grid-cols-12 ">
        {/* blok 1 */}
        <div className="col-span-1 lg:col-span-1 lg:row-span-4"></div>
        <div className="col-span-10 lg:col-span-4 lg:row-span-4 text-2xl  sm:text-5xl font-semibold text-black">
          <div>
            Baliejaren van CVT
          </div>
          <div className="text-base  mt-20">
            <table
              className="charts-css column data-spacing-6 show-labels hide-data show-data-on-hover"
              id="my-chart"
            >
              <tbody>
                <tr>
                  <th className="text-xl font-bold" scope="row">
                    {" "}
                    2011{" "}
                  </th>
                  <td style={{ "--size": "calc( 20 / 100 )" }}> 18 </td>
                </tr>
                <tr>
                  <th className="text-xl font-bold" scope="row">
                    {" "}
                    2017{" "}
                  </th>
                  <td style={{ "--size": "calc( 60 / 100 )" }} > 43 </td>
                </tr>
                <tr>
                  <th className="text-xl font-bold" scope="row">
                    {" "}
                    2019{" "}
                  </th>
                  <td style={{ "--size": "calc( 75 / 100 )" }}> 50 </td>
                </tr>
                <tr>
                  <th className="text-xl font-bold" scope="row">
                    {" "}
                    2020{" "}
                  </th>
                  <td style={{ "--size": "calc( 90 / 100 )" }}> 55 </td>
                </tr>
                <tr>
                  <th className="text-xl font-bold" scope="row">
                    {" "}
                    2022{" "}
                  </th>
                  <td style={{ "--size": "calc( 100 / 100 )" }}> 65 </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-1 lg:row-span-4"></div>

        {/* blok only mobile */}
        {/* <div className="col-span-1 lg:hidden lg:col-span-0 lg:row-span-0"></div>
        <div className="col-span-10 lg:hidden lg:col-span-0 lg:row-span-0  text-base sm:text-lg mt-12 text-black">
          <div>
            Het kantoor is opgericht in 2011 en sindsdien hebben we een groei van dossiers, vennoten en medewerkers. CVT is gericht op de toekomst.
          </div>
        </div>
        <div className="col-span-1 lg:hidden lg:col-span-0 lg:row-span-0"></div> */}
        {/* blok 2 */}
        <div className="col-span-1 lg:col-span-1 "></div>

        <div className="col-span-10 lg:col-span-4 ">
          <div className=" text-2xl sm:text-5xl font-semibold xl:mb-16 mt-24 lg:mt-0">
         
          </div>
        </div>
        <div className="col-span-1 lg:col-span-1"></div>
        {/* blok 3 */}

        <div className="col-span-1 md:col-start-2 lg:col-span-1"></div>
        <div className="col-span-10 md:col-span-8 lg:col-span-4  mt-14">
          <div className="text-left text-base sm:text-3xl lg:text-xl xl:text-3xl ">
            Opgericht
          </div>
          <div className="text-right text-2xl lg:text-lg xl:text-3xl mr-16">
            2011
          </div>
        </div>
        <div className="col-span-1 md:col-start-2 lg:col-span-1"></div>

        {/* blok 4 */}
        <div className="col-span-1 md:col-start-2 lg:col-span-1"></div>
        <div className="col-span-10 md:col-span-8 lg:col-span-4  mt-14">
          <div className="text-left text-base sm:text-3xl lg:text-xl xl:text-3xl ">
            Teamleden
          </div>
          <div className="text-right text-2xl lg:text-lg xl:text-3xl mr-16">
            7
          </div>
        </div>
        <div className="col-span-1 md:col-start-2 lg:col-span-1"></div>

        {/* blok 5 */}
        <div className="col-span-1 md:col-start-2 lg:col-span-1"></div>
        <div className="col-span-10 md:col-span-8 lg:col-span-4  mt-14">
          <div className="text-left text-base sm:text-3xl lg:text-xl xl:text-3xl ">
            Baliejaren
          </div>
          <div className="text-right text-2xl lg:text-lg xl:text-3xl mr-16">
            65
          </div>
        </div>
        <div className="col-span-1 md:col-start-2 lg:col-span-1"></div>
      </div>
    </div>
  );
};

export default Statistieken;

// vanaf LG veranderen naar een side view 6 + (1 - 4 -1) en vanaf 2xl naar een view waar ze naar elkaar zitten
