import * as React from "react"
import Layout from "../components/general/Layout";
import Hero from "../components/index/Hero"
import Waarden from "../components/index/Waarden"
import Statistieken from "../components/index/Statistieken"
import Ontdek from "../components/index/Ontdek"

import LeerOnsKennen from "../components/index/LeerOnsKennen"
import MainIntro from "../components/index/MainIntro"
import { Helmet } from "react-helmet";




// markup
const IndexPage = () => {
  return (
    //layout staat vanboven
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          href="//cdn.jsdelivr.net/chartist.js/latest/chartist.min.css"
        />
        <script src="//cdn.jsdelivr.net/chartist.js/latest/chartist.min.js"></script>
      </Helmet>

      <Hero></Hero>

      <Ontdek></Ontdek>
      <Waarden></Waarden>

      <Statistieken></Statistieken>
      <LeerOnsKennen></LeerOnsKennen>
    </Layout>
  );
}

export default IndexPage
